import { registerApplication, start } from "single-spa";
import * as isActive from "./activity-functions";
//if (!navigator.onLine) {
//  const OFFLINE_URL = `${backendConstants.FE_ROOT_SPA_BASE_URL}/${backendConstants.FE_ROOT_SPA_REACT_PREFIX}`;
//  window.location.href = OFFLINE_URL;
//}
/*if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register(
        "./serviceWorker.js" , {
        scope: `${backendConstants.FE_SUBFOLDER ?? "/"}`,
      }
      )
      .then((res) => {
        console.log("service worker registered");
      })
      .catch((err) => console.log("service worker not registered", err));
  });
}*/

if (location.href === location.origin + "/") {
  window.location.replace(location.href + "#/");
} /*else if(location.href===location.origin+"/ia3"){
	window.location.replace(location.href+"/#/");
} else if(location.href===location.origin+"/ia3/"){
	window.location.replace(location.href+"#/");
}*/
registerApplication(
  "@ITT/single-spa-react",
  () => System.import(process.env.REACT_APP_FE_ROOT_SINGLE_SPA_URL),
  () => isActive.react(location),
  { domElement: document.getElementById("react-container") }
);
registerApplication(
  "@ITT/single-spa-aurelia",
  () =>
    System.import(
      `${process.env.AURELIA_APP_FE_ROOT_SINGLE_SPA_URL}single-spa/ITT-single-spa-aurelia-prod.js`
    ),
  () => isActive.aurelia(location),
  { domElement: document.getElementById("aurelia-container") }
);
start();
