export function hasPrefix(location, ...prefixes) {
  return prefixes.some(
    (prefix) => location.href.indexOf(`${location.origin}/${prefix}`) !== -1
  );
}

export function react(location) {
  return location.href.indexOf("v2#") >= 0;
}

export function aurelia(location) {
  return hasPrefix(location, "#") && location.href.indexOf("v2#") < 0;
}
